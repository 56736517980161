import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../Services/auth.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";

declare var $: any;


@Component({
  selector: "app-header-main",
  templateUrl: "./header-main.component.html",
  styleUrls: ["./header-main.component.scss"]
})
export class HeaderMainComponent implements OnInit {
  public loggedIn: boolean;
  public jwt = localStorage.getItem("token");
  public jwtData;
  public decodedJwtJsonData;
  public decodedJwtData;
  public role;
  constructor(
    private router: Router,
    private Auth: AuthService,
    private Token: TokenService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // }

    // this.router.events.subscribe((evt) => {
    //   if (evt instanceof HeaderMainComponent) {
    //     this.router.navigated = false;        
    //   }
    // });
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe(value => (this.loggedIn = value));
    if (this.loggedIn) {
      this.jwt = localStorage.getItem('token');
      this.jwtData = this.jwt.split(".")[1];
      this.decodedJwtJsonData = window.atob(this.jwtData);
      this.decodedJwtData = JSON.parse(this.decodedJwtJsonData);
      this.role = this.decodedJwtData.role;
    }
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl("/signin/signin-step");
  }
  toggleMobileMenu() {
    $(".mobile-menuu").css("right", "0");

  }
  closeMobileMenu() {
    $(".mobile-menuu").css("right", "-215px");

  }
}
