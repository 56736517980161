import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'environments/environment';

import { NbAuthService, NbTokenService } from '@nebular/auth';
import { NbAuthJWTToken } from '@nebular/auth';

@Component({
  selector: 'ngx-checklogin',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class checkloginComponent {

 
  public token = null;



  private baseUrl = environment.apiUrl;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: NbAuthService
  ) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.router.navigateByUrl('/pages/dashboard');
        } else {
           this.router.navigateByUrl('/login');
        }
      });

  }


}
