import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	public loggedIn : boolean;

  constructor(
  	private router : Router,
  	private Auth : AuthService,
  	private Token : TokenService,
  	) {
  	 
   }

  ngOnInit() {
  	 this.Auth.authStatus.subscribe(value => this.loggedIn = value);

  }

  logout(event : MouseEvent){

  	event.preventDefault();
  	this.Token.remove();
  	this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/signin/signin-step');	

  }

}
