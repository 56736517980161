import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';


@Injectable({ providedIn: 'root' })
export class DataService {

  private userName$ = new BehaviorSubject<String>('name');
 public curentname$ = this.userName$.asObservable();
  constructor() { }

  updatedDataSelection(name: String){
    this.userName$.next(name);
  }
  
}