import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  // NbRegisterComponent,
  // NbRequestPasswordComponent,
  // NbResetPasswordComponent,
} from '@nebular/auth';
// import { LandingContentComponent } from './components/landing-content/landing-content.component';
// import { DtCoreModule } from './dt-broker/dt-core/dt-core.module';
// import { DtCoreRoutingModule } from './dt-broker/dt-core/dt-core-routing.module';
// import { MyAccountComponent } from './components/my-account/my-account.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { AuthService } from './guards/auth.service';
// import { BrokerMembershipComponent } from './components/broker-membership/broker-membership.component';
// import { BrokerCompleteSignupComponent } from './components/broker-membership/broker-complete-signup/broker-complete-signup.component';
// import { BrokerMembershipUpgradeComponent } from './components/broker-membership/broker-membership-upgrade/broker-membership-upgrade.component';
// import { BrokerMembershipPlanUpgradePaymentComponent } from './components/broker-membership/broker-membership-upgrade/broker-membership-plan-upgrade-payment/broker-membership-plan-upgrade-payment.component';

import { checkloginComponent } from './checklogin/checklogin.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuardService],
    loadChildren: 'app/pages/pages.module#PagesModule'

  },
  { 
    path: 'created/preview/:id',
    loadChildren: 'app/preview/preview.module#PreviewModule'
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: checkloginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      // {
      //   path: 'register',
      //   component: NbRegisterComponent,
      // },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      // {
      //   path: 'request-password',
      //   component: NbRequestPasswordComponent,
      // },
      // {
      //   path: 'reset-password',
      //   component: NbResetPasswordComponent,
      // },
    ],
  },
  {
    path: '',
    // canActivate: [AuthGuardService],
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: checkloginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      // {
      //   path: 'register',
      //   component: NbRegisterComponent,
      // },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      // {
      //   path: 'request-password',
      //   component: NbRequestPasswordComponent,
      // },
      // {
      //   path: 'reset-password',
      //   component: NbResetPasswordComponent,
      // },
    ],
  },
  {
    path: '',
    component: NbAuthComponent,
    children: [
      {
        path: '**',
        component: checkloginComponent,
      },
      {
        path: 'login',
        component: checkloginComponent,
      },
      // {
      //   path: 'register',
      //   component: NbRegisterComponent,
      // },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      // {
      //   path: 'request-password',
      //   component: NbRequestPasswordComponent,
      // },
      // {
      //   path: 'reset-password',
      //   component: NbResetPasswordComponent,
      // },
    ],
  },
  // {
  //   path: 'signup',
  //   loadChildren: './components/signup-m/signup-m.module#SignupMModule',
  // },
  // {
  //   path: '',
  //   loadChildren: './components/landing-content-m/landing-content-m.module#LandingContentMModule',
  // },
  // // {
  // //   path: '**',
  // //   loadChildren: './components/coming-soon-m/coming-soon-m.module#ComingSoonMModule',
  // // },
  // // {
  // //   path: 'user',
  // //   loadChildren: './components/landing-content-m/landing-content-m.module#LandingContentMModule',
  // // },
  // // {
  // //   path: 'user/:id',
  // //   loadChildren: './components/landing-content-m/landing-content-m.module#LandingContentMModule',
  // // },
  // {
  //   path: 'signin',
  //   loadChildren: './components/sign-in-m/sign-in-m.module#SignInMModule',
  // },
  // {
  //   path: 'profile',
  //   loadChildren: './components/profile-m/profile-m.module#ProfileMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'coming-soon',
  //   loadChildren: './components/coming-soon-m/coming-soon-m.module#ComingSoonMModule',
  // },
  // {
  //   path: 'my-account',
  //   loadChildren: './components/my-account/my-account.module#MyAccountModule',
  // },
  // {
  //   path: 'email-preference',
  //   loadChildren: './components/email-preference-m/email-preference-m.module#EmailPreferenceMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'search-result',
  //   loadChildren: './components/search-result-m/search-result-m.module#SearchResultMModule',
  // },
  // {
  //   path: 'broker-search',
  //   loadChildren: './components/broker-search-m/broker-search-m.module#BrokerSearchMModule',
  // },
  // {
  //   path: 'saved-searches',
  //   loadChildren: './components/saved-serches-m/saved-serches-m.module#SavedSerchesMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'saved-listing',
  //   loadChildren: './components/saved-listings-m/saved-listings-m.module#SavedListingsMModule',
  //   canActivate: [AuthGuardService]
  // },
  // // {
  // //   path: 'broker-membership',
  // //   component: BrokerMembershipComponent,
  // // },
  // {
  //   path: 'broker-membership',
  //   loadChildren: './components/broker-membership-m/broker-membership-m.module#BrokerMembershipMModule',
  // },
  // {
  //   path: 'broker-details/:id',
  //   loadChildren: './components/broker-details-m/broker-details-m.module#BrokerDetailsMModule',
  // },
  // {
  //   path: 'view-listing/:id',
  //   loadChildren: './components/list-search-single-m/list-search-single-m.module#ListSearchSingleMModule',
  // },
  // {
  //   path: 'user-listing',
  //   loadChildren: './components/addlist-m/addlist-m.module#AddlistMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'contact',
  //   loadChildren: './components/contact-m/contact-m.module#ContactMModule',
  // },
  // {
  //   path: 'about',
  //   loadChildren: './components/about-us/about-us.module#AboutUsModule',
  // },
  // {
  //   path: 'emailalert',
  //   loadChildren: './components/emailalert-m/emailalert-m.module#EmailalertMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'how-to-sell',
  //   loadChildren: './components/how-to-sell-m/how-to-sell-m.module#HowToSellMModule',

  // },
  // {
  //   path: 'equity',
  //   loadChildren: './components/equity-m/equity-m.module#EquityMModule',
  // },
  // {
  //   path: 'my-mail-box',
  //   loadChildren: './components/usermailbox-m/usermailbox-m.module#UsermailboxMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'guide-to-selling',
  //   loadChildren: './components/guide-to-selling/guide-to-selling.module#GuideToSellingModule',
  // },
  // {
  //   path: 'guide-to-buying',
  //   loadChildren: './components/guide-to-buying/guide-to-buying.module#GuideToBuyingModule',
  // },
  // {
  //   path: 'my-billing-information',
  //   loadChildren: './components/billing-information-m/billing-information-m.module#BillingInformationMModule',
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'broker-complete-signup-process',
  //   component: BrokerCompleteSignupComponent,
  // },
  // // {
  // //   path: 'broker-membership-upgrade',
  // //   component: BrokerMembershipUpgradeComponent,
  // // },
  // {
  //   path: 'broker-membership-upgrade-payment',
  //   component: BrokerMembershipPlanUpgradePaymentComponent,
  // },

];

 const config: ExtraOptions = {
   useHash: true
 };

@NgModule({
  imports: [RouterModule.forRoot(routes,config)],
  exports: [RouterModule],
  providers: [AuthGuardService, AuthService]
})

export class AppRoutingModule {
}

