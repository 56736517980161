import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../Services/auth.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  public loggedIn: boolean;
  public jwt = localStorage.getItem("token");
  public jwtData;
  public decodedJwtJsonData;
  public decodedJwtData;
  public role;
  constructor(
    private router: Router,
    private Auth: AuthService,
    private Token: TokenService
  ) {}

  ngOnInit() {
    this.Auth.authStatus.subscribe(value => (this.loggedIn = value));
    if (this.loggedIn) {
      this.jwtData = this.jwt.split(".")[1];
      this.decodedJwtJsonData = window.atob(this.jwtData);
      this.decodedJwtData = JSON.parse(this.decodedJwtJsonData);
      this.role = this.decodedJwtData.role;
    }
  }
}
