
import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from 'environments/environment';
import { DataService } from './../../../data.service';
import { HttpClient ,HttpClientModule,HttpHeaders} from '@angular/common/http';
import { TransfereDataServiceService } from '../../../transfere-data-service.service';



@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'

})


export class HeaderComponent implements OnInit {

imageUrl = environment.imageUrl;

  @Input() position = 'normal';

  
 // user = {};
user: any;
username:null;
token = null;
apiUrl = environment.apiUrl;

  //userMenu = [{ title: 'Profile' , link: '../pages/forms'}, { title: 'Log out' , link: '../admin/logout'}];
    userMenu = [{ title: 'Log out' , link: '../admin/logout'}];


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private authService: NbAuthService,
              private dataService: DataService,
              private transfereService: TransfereDataServiceService,
              private http: HttpClient) {


           this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable 
          this.token = token.getValue();
        }

      });     
  }

//  ngOnInit() {
//    this.userService.getUsers()
//      .subscribe((users: any) => this.user = users.nick);
//  }

   ngOnInit() {
    this.userService.getUsers()
      .subscribe((users: any) => this.user);
     

      this.getuserhead(this.user.userid);

  }
//{this.imageUrl+''+}adminpics/
//1585283455.png

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }





getuserhead(userid){
  const fd = new FormData(); 
  fd.append('token',this.token);
  fd.append('userid', userid);
  // var res.data['name']='';
  // var res.data['lastname']='';
  // var res.data['picture']='';
    var res: any;
        this.transfereService.getUserHead(fd).subscribe(response => {
             this.username = response.data['name'] + response.data['lastname'];
               if((response.data['picture'] != null)&&(response.data['picture'])){
                      this.user.picture = this.imageUrl+'adminpics/'+response.data['picture'];
                    }
        });


        // this.http.post(`${this.apiUrl}getUserHead`,fd).subscribe(res => {
        //   console.log(res);

        //     for (var data in res){
        //         this.username = res.data['name'] + res.data['lastname'];
        //        if((res.data['picture'] != null)&&(res.data['picture'])){
        //               this.user.picture = this.imageUrl+'adminpics/'+res.data['picture'];
        //             }
        //   }

               //this.dataService.curentname$.subscribe(this.username => this.username = res.data['name'] + res.data['lastname']);
               //this.dataService.curentname$.subscribe(this.username => this.username = res.data['name'] + res.data['lastname']);
               //  this.username = res.data['name'] + res.data['lastname'];
               // if((res.data['picture'] != null)&&(res.data['picture'])){
               //        this.user.picture = this.imageUrl+'adminpics/'+res.data['picture'];
               //      }
          // });

}
  



}

