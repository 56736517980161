import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../Services/auth.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";
@Component({
  selector: "ngx-signin-header",
  templateUrl: "./signin-header.component.html",
  styleUrls: [
    "./signin-header.component.scss",
    "../../../assets/assetz/sign-in/css/sign-in.css",
    "../../../assets/assetz/sign-in/css/sign-in-media.css"
  ]
})
export class SigninHeaderComponent implements OnInit {
  public loggedIn: boolean;
  constructor(
    private Auth: AuthService,
    private Token: TokenService,
    private router: Router
  ) {}
  public jwt = localStorage.getItem("token");
  public jwtData;
  public decodedJwtJsonData;
  public decodedJwtData;
  public role;
  ngOnInit() {
    this.Auth.authStatus.subscribe(value => (this.loggedIn = value));
    if (this.loggedIn) {
      this.jwtData = this.jwt.split(".")[1];
      this.decodedJwtJsonData = window.atob(this.jwtData);
      this.decodedJwtData = JSON.parse(this.decodedJwtJsonData);
      this.role = this.decodedJwtData.role;
    }
  }
  logout(event: MouseEvent) {
    event.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl("/signin/signin-step");
  }
}
