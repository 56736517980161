import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient ,HttpClientModule} from '@angular/common/http';
import { environment } from 'environments/environment';

import { Observable, of } from 'rxjs';

import {  NbAuthService,NbTokenService} from '@nebular/auth';
import { NbAuthJWTToken } from '@nebular/auth';


@Injectable({
  providedIn: 'root'
})
export class TransfereDataServiceService {
private baseUrl = environment.apiUrl;


private data;
private permissioncheck;
public token = null;

  constructor(
    private router:Router,
    private http: HttpClient,
    private authService: NbAuthService

  ) { 
    this.authService.onTokenChange()
          .subscribe((token: NbAuthJWTToken) => {

            if (token.isValid()) {
            this.token = token.getValue();
            }
    });
  }

  setData(data){
    this.data = data;
    console.log(this.data);

  }

  getData(){
    let temp = this.data;
    this.clearData();
    return temp;
  }

  clearData(){
    this.data = undefined;
  }

  checkPermisssion(permissioncheck){
         this.http.post(`${this.baseUrl}permissionsarray`,permissioncheck).subscribe((res: Array<object>)=>{
              return 'res';
          });


//       this.http.post(`${this.baseUrl}checkpermission`,permissioncheck).subscribe((res)=>{
//          return res;
//      });
  }



getpermissions (fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}permissionspaths`,fd);
}

getpermissionsPaths (fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}perpathstring`,fd);
}


permissionsarray (fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}permissionsarray`,fd);
}

getall (fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}getallpermissions`,fd);
}
getpagepermissions (fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}getpagepermissions`,fd);
}
checkexitemail(fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}checkexitemail`,fd);
}


getUserHead(fd): Observable<any> {

return this.http.post<any>(`${this.baseUrl}getUserHead`,fd);
}



}
