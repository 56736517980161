
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs/Observable";
import * as jwt_decode from 'jwt-decode';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';

@Injectable()
export class AuthService {
  // constructor(private myRoute: Router) { }
  
constructor(
    private myRoute: Router,
    private authService: NbAuthService
  ) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (!token.isValid()) {
         localStorage.clear();
       }else{
        this.setToken(token.getValue())
       }
      });


  }


  setToken(token: string) {
    localStorage.setItem("token", token)
  }
  getToken() {
    return localStorage.getItem("token")
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  // getTokenExpirationDate(token: string): Date {
  //   const decoded = jwt_decode(token);

  //   if (decoded.exp === undefined) return null;

  //   const date = new Date(0); 
  //   date.setUTCSeconds(decoded.exp);
  //   return date;
  // }
  // isTokenExpired(token?: string): boolean {
  //   if(!token) token = this.getToken();
  //   if(!token) return true;

  //   const date = this.getTokenExpirationDate(token);
  //   if(date === undefined) return false;
  //   return !(date.valueOf() > new Date().valueOf());
  // }
  logout() {
    localStorage.removeItem("token");
    this.myRoute.navigate(["/signin"]);
  }
}