import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeModule } from './@theme/theme.module';
import { RouterModule } from '@angular/router';



import { FooterComponent } from './components/footer/footer.component';
import { HeaderMainComponent } from './components/header-main/header-main.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';




@NgModule({
  imports: [
        CommonModule,
        ThemeModule,
        RouterModule
  ],
  declarations: [
    FooterComponent,
    HeaderMainComponent,
    SidebarComponent,
  ],
  exports: [HeaderMainComponent,FooterComponent,SidebarComponent],

})
export class HeaderfooterModule { }









