import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
showHead: boolean = false;

  constructor() { }

  ngOnInit() {
  const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }

  }

}
