import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JarwisService {
  //	private baseUrl = "http://localhost:8000/api";

  //private baseUrl = "http://localhost/robin/angularlarvel/ajmiwork/dealtata4/back-end/public/api";
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  signup(data) {
    return this.http.post(`${this.baseUrl}signup`, data)
  }
  signin(data) {
    return this.http.post(`${this.baseUrl}login`, data)
  }
  list(data) {
    return this.http.post(`${this.baseUrl}show`, data)
  }
  getLists() {
    return this.http.get(`${this.baseUrl}getlist`)
  }
  getmylisting() {
    return this.http.get(`${this.baseUrl}ng`)
  }
  deleteList(id) {

    return this.http.post(`${this.baseUrl}deleteList`, { params: { 'id': id } })
  }
  getCategorys() {
    return this.http.get(`${this.baseUrl}getcategory`)
  }
  getUserData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getUserData?token=` + localStorage.getItem('token'), data);
  }
  updateUserData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}updateUserData?token=` + localStorage.getItem('token'), data);
  }
  getPreferences(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getPreferences?token=` + localStorage.getItem('token'), data);
  }
  savePreferences(data): Observable<any> {
    return this.http.post(`${this.baseUrl}savePreferences?token=` + localStorage.getItem('token'), data);
  }
  getStates(data): Observable<any> {
    return this.http.post(`${this.baseUrl}business_location?token=` + localStorage.getItem('token'), data);
  }
  getStateName(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getStateName?token=` + localStorage.getItem('token'), data);
  }
  getBusinessCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getBusinessCategory`, data);
  }
  getCounty(data): Observable<any> {
    return this.http.post(`${this.baseUrl}county`, data);
  }
  getPurchSearchResult(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getPurchSearchResult`, data);
  }
  getBrokSearchResult(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getBrokSearchResult`, data);
  }
  getYear(data) {
    return this.http.post(`${this.baseUrl}getYear`, data);
  }
  getListingDataByID(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getListingDataByID`, data);
  }
  getListingDataByCatID(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getListingDataByCatID`, data);
  }
  getUserDataByID(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getUserDataByID`, data);
  }
  getRecentListing(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getRecentListing`, data);
  }
  getCountries(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getCountries`, data);
  }
  getLocSuggetion(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getLocSuggetion`, data);
  }
  getStateByCode(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getStateByCode`, data);
  }
  getRealestate(): Observable<any> {
    return this.http.get(`${this.baseUrl}getRealestate`);
  }
  saveadstep2(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveadstep2`, data);
  }
  mylisting(data): Observable<any> {
    return this.http.post(`${this.baseUrl}mylisting`, data);
  }
  userfetchdata(data): Observable<any> {
    return this.http.post(`${this.baseUrl}userfetchdata`, data);
  }
  getTopBrokers(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getTopBrokers`, data);
  }
  saveListSearch(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveListSearch?token=` + localStorage.getItem('token'), data);
  }
  getMySearches(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getMySearches?token=` + localStorage.getItem('token'), data);
  }
  getSearchByID(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getSearchByID`, data);
  }
  saveMyListing(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveMyListing?token=` + localStorage.getItem('token'), data);
  }
  getSavedListing(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getSavedListing?token=` + localStorage.getItem('token'), data);
  }
  getBrokerDetails(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getBrokerDetails`, data);
  }
  getMyListings(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getMyListings`, data);
  }
  getpreviewcontent(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getpreviewcontent`, data);
  }
  getUsCounties(): Observable<any> {
    return this.http.get(`${this.baseUrl}getUsCounties`);
  }
  getCountyByState(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getCountyByState`, data);
  }
  getTopRecommendation(): Observable<any> {
    return this.http.get(`${this.baseUrl}getTopRecommendation?token=` + localStorage.getItem('token'));
  }
  // mailbox starts
  // getLeadsByUser(data):Observable<any>{
  //   return this.http.post(`${this.baseUrl}getLeadsByUser`,data);
  // }
  getLeadsByUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getLeadsByUser`, data);
  }
  getLeadsByUsersingle(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getLeadsByUsersingle`, data);
  }
  getMessagesUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getMessagesUser`, data);
  }
  getSellerMessagesByUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getSellerMessagesUser`, data);
  }
  getMessagesSeller(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getMessagesSeller`, data);
  }
  saveReplyMessages(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveReplyMessages`, data);
  }
  saveSellerReplyMessages(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveSellerReplyMessages`, data);
  }
  getsellerByUsersingle(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getsellerByUsersingle`, data);
  }
  getFeaturedBusi(): Observable<any> {
    return this.http.get(`${this.baseUrl}getFeaturedBusi`);
  }
  SaveEnquiry(data): Observable<any> {
    return this.http.post(`${this.baseUrl}SaveEnquiry`, data);
  }
  getnonregisUsersleads(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getnonregisUsersleads`, data);
  }
  getMessagesnonregusr(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getMessagesnonregusr`, data);
  }
  saveReplyNonregusrMessages(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveReplyNonregusrMessages`, data);
  }
  getEliteBroker(): Observable<any> {
    return this.http.get(`${this.baseUrl}getEliteBroker`);
  }
  getFeaturedList(): Observable<any> {
    return this.http.get(`${this.baseUrl}getFeaturedList`);
  }
  getBusinessBroker(): Observable<any> {
    return this.http.get(`${this.baseUrl}getBusinessBroker`);
  }
  getCustomResults(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getCustomResults`, data);
  }
  get_featured_user(): Observable<any> {
    return this.http.get(`${this.baseUrl}get_featured_user`);
  }
  getleadscount(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getleadscount`, data);
  }
  deletelisting(data): Observable<any> {
    return this.http.post(`${this.baseUrl}deletelisting`, data);
  }
  deletealert(data): Observable<any> {
    return this.http.post(`${this.baseUrl}deletealert`, data);
  }
  getUserDetails(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getUserDetails`, data);
  }
  getBrokerProfileData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getBrokerProfileData`, data);
  }
  saveContact(data): Observable<any> {
    return this.http.post(`${this.baseUrl}saveContact`, data);
  }
  account_verification(data): Observable<any> {
    return this.http.post(`${this.baseUrl}verify-user-account`, data);
  }
  checkUserExist(data): Observable<any> {
    return this.http.post(`${this.baseUrl}checkUserExist`, data);
  }
  getemailalerts(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getemailalertdata`, data);
  }
  getUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getUser`, data);
  }
  mylistingsort(data): Observable<any> {
    return this.http.post(`${this.baseUrl}mylistingsort`, data);
  }
  resetpass(data): Observable<any> {
    return this.http.post(`${this.baseUrl}resetpassword`, data);
  }
  resetpasstodb(data): Observable<any> {
    return this.http.post(`${this.baseUrl}resetpasstodb`, data);
  }
  getadowner(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getadowner`, data);
  }
  getcontactowner(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getcontactowner`, data);
  }
  getbrokers(): Observable<any> {
    return this.http.get(`${this.baseUrl}getbrokers`);
  }
  assigntobroker(data): Observable<any> {
    return this.http.post(`${this.baseUrl}assigntobroker`, data);
  }
  getaddetails(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getadddetails`, data);
  }
  verifylead(data): Observable<any> {
    return this.http.post(`${this.baseUrl}verifylead`, data);
  }
  getListingAtUrl(data): Observable<any> {
    return this.http.post(data.paginateurl, data);
  }
  checkSavedStatus(data): Observable<any> {
    return this.http.post(`${this.baseUrl}checkSavedStatus`, data);
  }
  sortListing(data): Observable<any> {
    return this.http.post(`${this.baseUrl}sortListing?token=` + localStorage.getItem('token'), data);
  }
  sortSearch(data): Observable<any> {
    return this.http.post(`${this.baseUrl}sortSearch?token=` + localStorage.getItem('token'), data);
  }
  getexpirestatus(data): Observable<any> {
    return this.http.post(`${this.baseUrl}expireplans`, data);
  }
  getnonregByUsersingle(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getnonregByUsersingle`, data);
  }
  getUserByListId(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getUserByListId`, data);
  }
  deleteSavedSearches(data): Observable<any> {
    return this.http.post(`${this.baseUrl}deleteSavedSearches`, data);
  }
  getSavedSearchCriteria(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getSavedSearchCriteria?token=` + localStorage.getItem('token'), data);
  }
  getSearchLink(data): Observable<any> {
    return this.http.post(`${this.baseUrl}getSearchLink?token=` + localStorage.getItem('token'), data);
  }
}
