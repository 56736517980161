import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { JarwisService } from '../../Services/jarwis.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
declare var require: any;
const Swal = require('sweetalert2');
import { environment } from '../../../environments/environment'; 
declare var $:any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  assecturl = environment.assecturl;
  constructor(private Jarwis:JarwisService,
    private router : Router,
    private  _router : Router) { }

  token: any;

  ngOnInit() {
    this.token  = localStorage.getItem('token');
    this.loadScript();

    $(document).on("click","clickable",function(){
        $(this).parents(".card").find(".clickable").removeClass("activeListItem");
        $(this).addClass("activeListItem");
        console.log("hey");
    });
    $(".dashboard-navigation-mobile").click(function(){
      $(this).siblings(".navigation-panel").toggleClass("open");
    });
  }
  checkexpirestatus()
  {
    const fd = new FormData(); 
    fd.append('token',this.token);
    this.Jarwis.getexpirestatus(fd).subscribe(data => this.handlecheckexpireresponse(data));
  }
  handlecheckexpireresponse(data)
  {
    if(data.status == 0)
    {
      Swal.fire({
        title: 'Your plan has expired !!!',
        text: 'Do you want to upgrade the plan ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Upgrade',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          this.router.navigate(['user-listing/upgrade-plan']);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Please upgrade the plan for add more listings !!!',
            'error'
          )
        }
      });
    }else{
      this.router.navigate(['user-listing/addlist']);
    }
  }
    // Load scripts for this page
    public loadScript() {        
      var dynamicScripts = [`${this.assecturl}/assetz/js/landing-js.js`
      ];
      console.log(dynamicScripts);
        for (var i = 0; i < dynamicScripts .length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
}
