import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JarwisService } from '../../Services/jarwis.service';

@Component({
  selector: 'ngx-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail-list.component.scss',
  '../../../assets/assetz/user/maillist/css/my-mailbox.css',
  '../../../assets/assetz/user/maillist/css/main-inner.css',
  '../../../assets/assetz/user/maillist/css/main-media.css'
  ],

})
export class MailListComponent implements OnInit {
  token =null;
  data = null;
  assecturl = environment.assecturl;
  showHead: boolean = true;
  public countries: Array<object> = [];

  constructor( private Jarwis:JarwisService,
               private router : Router,
               private http: HttpClient) { }

  ngOnInit() {
    
  }


}

  














